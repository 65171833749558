import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { SelectItem } from "primeng/api";

import { CoreService } from 'src/app/core/core.service';
import { Unit } from 'src/app/interfaces/unit';
import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: "cm-units",
  templateUrl: "./units.component.html",
  styleUrls: ["./units.component.scss"]
})
export class UnitsComponent implements OnInit {
  units: Unit[] = [];
  unitList: any = [];
  currentUnit: Unit = new Unit;
  banner: string;
  isTerzius:boolean;

  cm_select_city: SelectItem[];
  lat: number;
  lng: number;
  zoom: number = 15;

  constructor(
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService) {}

  ngOnInit() {
    this._getUnits();
    this._getBanner();
    this.isTerzius = Constants.isTerzius;
    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
  }

  // ----- PUBLIC METHODS
  selectUnitData(e): void {
    this.currentUnit = this.units.find((unit: any) => {
      if(unit.id == e.value){
        this.lat = unit.lat;
        this.lng = unit.long;
        return unit;
      }
    });
  }

  // ----- PRIVATE METHODS
  private _getUnits(): void {
    this._coreService.getUnits().subscribe((response: any) => {
      this.units = response;
      this.unitList = response.map(unit => {
        return {label: unit.cidade, value: unit.id}
      })
      this.currentUnit = this.units[0];
      this.lat = this.units[0].lat;
      this.lng = this.units[0].long;
    })
  }

  private _getBanner(): void {
    const params = {page: 'unidades'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      // this.banner = response.image_url;
      this.banner = "../../assets/BG.jpg"
    })
  }
}
