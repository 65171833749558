import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoreService } from "src/app/core/core.service";
import { Item, Address, PaymentOptions } from "src/app/interfaces/cart";
import { CurrencyPipe } from "@angular/common";
import { ClientCart } from "src/app/interfaces/clientCart";
import { PurchaseResume } from "src/app/interfaces/purchase-resume";
import { CartService } from "src/app/services/cart.service";
import { GenericValidator } from "src/app/validators/cpf-validator";
import { Constants } from "src/app/shared/constants.service";
import { CartCheckoutService } from "src/app/services/cart-checkout.service";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { Installment, Installments, PayloadInstallments } from "src/app/interfaces/installments";

@Component({
  selector: "cm-cart-checkout",
  templateUrl: "./cart-checkout.component.html",
  styleUrls: ["./cart-checkout.component.scss"],
})
export class CartCheckoutComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    email: ["", [Validators.email, Validators.required]],
    name: ["", Validators.required],
    birth: ['', Validators.required],
    cpf: ["", [Validators.required, GenericValidator.isValidCpf()]],
    phone: ["", Validators.required],
    cep: [""],
    uf: ["", Validators.required],
    number: ["", Validators.required],
    address: ["", Validators.required],
    city: ["", Validators.required],
    neighborhood: ["", Validators.required],
    complement: [""],
    card_number: [""],
    expire_date: [""],
    card_holder: [""],
    cvv: [""],
    installments: [""],
    cpf_holder: [""],
    holder_number: [""],
    email_holder: [""],
    cep_holder: [""],
    state_holder: [""],
    address_number_holder: [""],
    address_holder: [""],
    city_holder: [""],
    neighborhood_holder: [""],
    complement_holder: [""],
  });


  captchaResponse: string;
  address: Address;
  paymentOptions: PaymentOptions;
  items: Item[] = [];
  resumeCart: PurchaseResume = {
    subtotal: {
      label: "R$ 0,00",
      value: 0,
    },
    discount: {
      label: "---",
      value: 0,
    },
    shipping: {
      label: "Grátis",
      value: 0,
    },
    total: {
      label: "R$ 0,00",
      value: 0,
    },
  };
  paymentResponse: any = {
    message: '',
    messageInvoice: '',
    processorCode: '',
  };
  paymentMethodTypeSelected: string = "";
  isMethodCardSelected: boolean = false;
  isMethodTicketSelected: boolean = false;
  isDifferentHolder: boolean = false;
  isDifferentHolderAddress: boolean = false;
  isCompletedPurchase: boolean = false;
  flipCard: boolean = false;
  showMsgCodeCopied: boolean = false;
  showModalCrossData: boolean = false;

  showImageTerm: boolean = false;
  showCheckoutButton: boolean = true;
  professions = [];
  specializations = [];
  installments: Installment[] = [];

  card = {
    card_number: "",
    card_holder: "",
    cvv: "",
    expire_date: "",
  };
  terms = {
    privacity: false,
    image: false,
  };


  @ViewChild("cardWrapper") cardWrapper: ElementRef;
  @ViewChild("codeBoleto") codeBoleto: ElementRef;

  constructor(
    private _currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private coreService: CoreService,
    private cartCheckoutService: CartCheckoutService
  ) {}

  ngOnInit() {
    this.items = this.cartService.getItemsFromCart();
    this.address = this.cartService.getAddressFromCart();
    this.paymentOptions = this.cartService.getPaymentOptionsFromCart();

    this.form.controls["cep"].setValue(this.address.shippingDestiny);

    if (this.address.status) {
      this.form.controls["address"].setValue(this.address.address);
      this.form.controls["neighborhood"].setValue(this.address.neighborhood);
      this.form.controls["city"].setValue(this.address.city);
      this.form.controls["uf"].setValue(this.address.uf);
    }

    this.calculateResumeSubTotal();
    this.calculateShipping();
    this.calculateDiscountValue();
    this.calculateResumeTotal();
    this.getTotalInstallments();
  }

  changeMethodPayment(method: string) {
    if (method === "credit_card") {
      this.isMethodCardSelected = true;
      this.isMethodTicketSelected = false;
      this.paymentMethodTypeSelected = "credit_card";
      this.UpdateResumeValue();
      this.enableCardPaymentInputsValidators();
    } else if (method === "invoice") {
      this.isMethodCardSelected = false;
      this.isMethodTicketSelected = true;
      this.paymentMethodTypeSelected = "invoice";
      this.isDifferentHolder = false;
      this.isDifferentHolderAddress = false;
      this.calculateDiscountValue();
      this.calculateResumeTotal();
      this.disableCardPaymentInputsValidators();
    }
  }

  toggleDifferentHolderData() {
    this.isDifferentHolder = !this.isDifferentHolder;
    this.updateCardWrapperSize();

    if (this.isDifferentHolder) this.enableOtherHolderInputsValidators();
    else this.disableOtherHolderInputsValidators();
  }

  toggleDifferentHolderAddress() {
    this.isDifferentHolderAddress = !this.isDifferentHolderAddress;
    this.updateCardWrapperSize();

    if (this.isDifferentHolderAddress)
      this.enableOtherHolderAddressInputsValidators();
    else this.disableOtherHolderAddressInputsValidators();
  }

  changeFlipChard() {
    this.flipCard = !this.flipCard;
  }

  updateCardWrapperSize() {
    if (this.isDifferentHolder && !this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `720px`;
    } else if (!this.isDifferentHolder && this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `840px`;
    } else if (this.isDifferentHolder && this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `1040px`;
    } else if (!this.isDifferentHolder && !this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `540px`;
    }
  }

  formatExpireDate(validate: string) {
    let input = validate;

    input = input.replace(/\D/g, "");

    if (input.length > 2) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }

    this.form.get("expire_date").setValue(input);
    this.card.expire_date = input;
  }

  formatCardNumber(number: string) {
    number = number.replace(/(\d{4})(?=\d)/g, "$1 ");
    return number;
  }

  formatItemValue(value: number): string {
    return Number(value).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  calculateResumeSubTotal() {
    const value = this.items.reduce(
      (previousValue, currentValue) => previousValue + currentValue.value,
      0
    );

    this.resumeCart.subtotal.value = value;

    this.resumeCart.subtotal.label = this.formatItemValue(value);
  }

  calculateShipping() {
    const shippingValues = this.items.map((value) => {
      const type = value.shippingTypes.find(
        (shipping) => shipping.type == value.shippingTypeSelected
      );

      return type ? type.description.shipping_value : 0;
    });

    const value = shippingValues.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    this.resumeCart.shipping.value = value;

    if (value != 0) this.resumeCart.shipping.label = this.formatItemValue(value);
  }

  calculateDiscountValue() {
    let cart = this.cartService.getCart();
    let total = 0;
    for(let i in cart['combo']) {
      total += cart['combo'][i]['discount'];
    }
    this.resumeCart.discount.label = this.formatItemValue(total);
    this.resumeCart.discount.value = total;
  }

  calculateResumeTotal() {
    const total = this.resumeCart.shipping.value + this.resumeCart.subtotal.value - this.resumeCart.discount.value;

    this.resumeCart.total.value = total;
    this.resumeCart.total.label = this.formatItemValue(total);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse["response"];
  }

  getTotalInstallments() {
    const items = this.cartService.getItemsFromCart();
    const courses = items.map((item) => {
      return { id: item.id, cuponId: item.coupom_id}
    })

    const data: any = {
      is_package: false,
      blackfriday: false,
      courses: courses,
      shippingValue: this.resumeCart.shipping.value,
    };

    this.coreService.getInstallments(data).subscribe({
      next: (res: Installments[]) => {
        let inst = [];
        inst = res.map((item) => {
          let data = {
            label: `${item.installment}x de ${this._currencyPipe.transform(
              item.installment_price,
              "R$"
            )} (${
              item.installment_fee ? "com juros" : "sem juros"
            }) Total ${this._currencyPipe.transform(item.full_price, "R$ ")} `,
            price: item.full_price,
            fee: item.installment_fee,
            value: item.installment,
          };
          return data;
        });

        inst.sort(function (a, b) {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        });
        this.installments = inst;
        this.form.get("installments").setValue(1);
      },
      error: (err) => console.log(err),
    })
  }

  checkout() {

    let cart = this.cartService.getCart();

    this.isCompletedPurchase = false;
    this.showCheckoutButton = false;

    const courses = this.items.map((value) => {
      return {
        id: value.id,
        shippingTypeSelected: value.shippingTypeSelected,
        shippingValue: value.shippingTypeSelected ? value.shippingTypes.find((type) => type.type == value.shippingTypeSelected).description.shipping_value : 0,
        shippingTime: value.shippingTime,
        shippingDestiny: value.shippingDestiny,
      };
    });

    let payload = {
      installments: this.form.get("installments").value ? this.form.get("installments").value : 1,
      captchaResponse: this.captchaResponse,
      courses: courses,
      origem: "helpsmed",
      payment_method: this.paymentMethodTypeSelected,
      shippingValue: this.resumeCart.shipping.value,
      combo: cart['combo'],
      value_total: this.resumeCart.total.value
    }

    payload["personal_data"] = {
      email: this.form.get("email").value,
      name: this.form.get("name").value,
      birth: this.form.get("birth").value,
      cpf: this.form.get("cpf").value,
      phone: this.form.get("phone").value,
      cep: this.form.get("cep").value,
      uf: this.form.get("uf").value,
      number: this.form.get("number").value,
      address: this.form.get("address").value,
      city: this.form.get("city").value,
      neighborhood: this.form.get("neighborhood").value,
      complement: this.form.get("complement").value,
      image_term_accepted: this.terms.image,
    };

    if (this.paymentMethodTypeSelected == "credit_card") {
      if(this.isDifferentHolder) {
        payload['credit_card'] = {
          cpf: this.form.get("cpf_holder").value,
          phone: this.form.get("holder_number").value,
          email: this.form.get("email_holder").value
        }
      } else {
        payload['credit_card'] = {
          cpf: this.form.get("cpf").value,
          phone: this.form.get("phone").value,
          email: this.form.get("email").value,
        }
      }

      if(this.isDifferentHolderAddress) {
        payload['credit_card']['address'] = this.form.get("address_holder").value;
        payload['credit_card']['number'] = this.form.get("address_number_holder").value;
        payload['credit_card']['neighborhood'] = this.form.get("neighborhood_holder").value;
        payload['credit_card']['complement'] = this.form.get("complement_holder").value;
        payload['credit_card']['cep'] = this.form.get("cep_holder").value;
        payload['credit_card']['city'] = this.form.get("city_holder").value;
        payload['credit_card']['uf'] = this.form.get("state_holder").value;
      } else {
        payload['credit_card']['address'] = this.form.get("address").value;
        payload['credit_card']['number'] = this.form.get("number").value;
        payload['credit_card']['neighborhood'] = this.form.get("neighborhood").value;
        payload['credit_card']['complement'] = this.form.get("complement").value;
        payload['credit_card']['cep'] = this.form.get("cep").value;
        payload['credit_card']['city'] = this.form.get("city").value;
        payload['credit_card']['uf'] = this.form.get("uf").value;
      }

      payload['credit_card']['name_in_card'] = this.form.get("card_holder").value;
      payload['credit_card']['set_number'] = this.form.get("card_number").value;
      payload['credit_card']['expire_date'] = this.form.get("expire_date").value;
      payload['credit_card']['set_cvv_number'] = this.form.get("cvv").value;
    }

    this.coreService
    .courseSubscription(payload)
    .pipe(
      catchError(() => {
        this.cartCheckoutService.showErrorMessagePopup();
        this.showCheckoutButton = true;
        return throwError('Erro ao tentar realizar a compra');
      })
    )
    .subscribe((response) => {

        this.paymentResponse.message = response.message;
        this.paymentResponse.messageInvoice = response.messageInvoice || '';
        if(response.processorCode) {
          this.paymentResponse.processorCode = this.formatTicketCode(response.processorCode);
        }

        this.isCompletedPurchase = true;
        this.showCheckoutButton = true;

        setTimeout(() => {
          this.showModalCrossData = true;
          document.body.style.overflowY = 'hidden';
        }, 500);

        this.cartService.deleteAllItemsFromCart();
      }
    );
  }

  enableCardPaymentInputsValidators() {
    this.form.get("card_number").enable();
    this.form.get("expire_date").enable();
    this.form.get("card_holder").enable();
    this.form.get("cvv").enable();

    this.form.get("card_number").setValidators(Validators.required);
    this.form.get("expire_date").setValidators(Validators.required);
    this.form.get("card_holder").setValidators(Validators.required);
    this.form.get("cvv").setValidators(Validators.required);
  }

  disableCardPaymentInputsValidators() {
    this.form.get("card_number").disable();
    this.form.get("expire_date").disable();
    this.form.get("card_holder").disable();
    this.form.get("cvv").disable();
  }

  enableOtherHolderInputsValidators() {
    this.form.get("cpf_holder").enable();
    this.form.get("holder_number").enable();
    this.form.get("email_holder").enable();

    this.form
      .get("cpf_holder")
      .setValidators([Validators.required, GenericValidator.isValidCpf()]);
    this.form.get("holder_number").setValidators([Validators.required]);
    this.form.get("email_holder").setValidators([Validators.required]);
  }

  disableOtherHolderInputsValidators() {
    this.form.get("cpf_holder").disable();
    this.form.get("holder_number").disable();
    this.form.get("email_holder").disable();
  }

  enableOtherHolderAddressInputsValidators() {
    this.form.get("cep_holder").enable();
    this.form.get("address_holder").enable();
    this.form.get("address_number_holder").enable();
    this.form.get("neighborhood_holder").enable();
    this.form.get("city_holder").enable();
    this.form.get("state_holder").enable();

    this.form.get("cep_holder").setValidators([Validators.required]);
    this.form.get("address_holder").setValidators([Validators.required]);
    this.form.get("address_number_holder").setValidators([Validators.required]);
    this.form.get("neighborhood_holder").setValidators([Validators.required]);
    this.form.get("city_holder").setValidators([Validators.required]);
    this.form.get("state_holder").setValidators([Validators.required]);
  }

  disableOtherHolderAddressInputsValidators() {
    this.form.get("cep_holder").disable();
    this.form.get("address_holder").disable();
    this.form.get("address_number_holder").disable();
    this.form.get("neighborhood_holder").disable();
    this.form.get("city_holder").disable();
    this.form.get("state_holder").disable();
  }

  findAddress() {
    if (this.form.get("cep_holder").value.length != 9) return;
    const cep = this.form.get("cep_holder").value.split("-").join("");
    this.cartService
      .getItemsShippingValue({
        cep,
        courses_ids: [],
      })
      .subscribe((response: any) => {
        this.form.patchValue({
          state_holder: response.address.uf,
          city_holder: response.address.city,
          neighborhood_holder: response.address.neighborhood,
          address_holder: response.address.address,
        });
      });
  }

  changePrivacityTermStatus() {
    this.terms.privacity = !this.terms.privacity;
  }

  changeImageTermStatus() {
    this.terms.image = !this.terms.image;
  }

  copyCode() {
    const code = this.codeBoleto.nativeElement.textContent;

    window.navigator["clipboard"].writeText(code);
    this.showMsgCodeCopied = true;

    setTimeout(() => {
      this.showMsgCodeCopied = false;
    }, 1000);
  }

  showImageTerms() {
    this.showImageTerm = true;
  }

  hideImageTerms() {
    this.showImageTerm = false;
  }

  closeModalCrossData() {
    this.showModalCrossData = false;
    document.body.style.overflowY = 'auto';
  }

  onFormCrossSubmited(event: FormGroup) {
    this.showModalCrossData = false;
    document.body.style.overflowY = 'auto';
    console.log(event);
  }

   // addToPossibleCartAbandonment(): void {
  //   const { cpf, email, name, phone } = this.form.value;
  //   const courseIds = this.items.map((item) => item.id);
  //   const id = courseIds[0];

  //   const cart: ClientCart = {
  //     id_curso: id,
  //     cpf,
  //     nome: name,
  //     telefone: phone,
  //     email,
  //     origem: "helpsmed",
  //   };

  //   console.log(courseIds);
  //   console.log()

  //   this.coreService.addToCartAbandonment(cart).subscribe({
  //     error: (error) => console.error(error),
  //   });
  // }

  formatTicketCode(ticketCode: string) {
    let ticketFormatado = '';

    ticketCode.split('').forEach((value, index) => {
      if (index == 5 || index == 15 || index == 26) ticketFormatado += '.';
      if (index == 10 || index == 21 || index == 31 || index == 32 || index == 33 || index == 41) ticketFormatado += ' ';

      ticketFormatado += value;
    });

    return ticketFormatado;
  }

  UpdateResumeValue() {
    const installment = this.installments.find((item) => item.value == this.form.get('installments').value);

    this.resumeCart.total.label = this.formatItemValue(Number(installment.price));
    this.resumeCart.total.value = Number(installment.price);
  }
}
