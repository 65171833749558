import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControlName,
} from "@angular/forms";
import { debounceTime, finalize } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { CurrencyPipe } from "@angular/common";
import { Subject } from "rxjs";

import { SnotifyService } from "ng-snotify";

import { CoreService } from "src/app/core/core.service";
import { Constants } from "src/app/shared/constants.service";
import { DateFormat } from "src/app/services/date-format.service";
import { SeoService } from "src/app/services/seo.service";
import { GenericValidator } from "src/app/validators/cpf-validator";
import { WINDOW } from "@ng-toolkit/universal";
import { compileNgModuleFromRender2 } from "@angular/compiler/src/render3/r3_module_compiler";
import { forEach } from "@angular/router/src/utils/collection";
import { Course } from "src/app/interfaces/course";
import { formControlBinding } from "@angular/forms/src/directives/ng_model";
import { ClientCart } from "src/app/interfaces/clientCart";
// import { runInThisContext } from "vm";

@Component({
  selector: "cm-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  labelOptionsPayment: string = "";
  courseData = null;
  package_payments = null;
  financeComplementaryInfo: any;
  form: FormGroup;
  areaActingMirror: any;
  listAreaActing: any[] = [
    { id: 1, name: "Acupuntura" },
    { id: 2, name: "Alergia e imunologia" },
    { id: 3, name: "Anestesiologia" },
    { id: 4, name: "Angiologia" },
    { id: 5, name: "Cardiologia" },
    { id: 6, name: "Cirurgia cardiovascular" },
    { id: 7, name: "Cirurgia da mão" },
    { id: 8, name: "Cirurgia de cabeça e pescoço" },
    { id: 9, name: "Cirurgia do aparelho digestivo" },
    { id: 10, name: "Cirurgia geral" },
    { id: 11, name: "Cirurgia oncológica" },
    { id: 12, name: "Cirurgia pediátrica" },
    { id: 13, name: "Cirurgia plástica" },
    { id: 14, name: "Cirurgia torácica" },
    { id: 15, name: "Cirurgia vascular" },
    { id: 16, name: "Clínica médica" },
    { id: 17, name: "Coloproctologia" },
    { id: 18, name: "Dermatologia" },
    { id: 19, name: "Endocrinologia e metabologia" },
    { id: 20, name: "Endoscopia" },
    { id: 21, name: "Gastroenterologia" },
    { id: 22, name: "Genética médica" },
    { id: 23, name: "Geriatria" },
    { id: 24, name: "Ginecologia e obstetrícia" },
    { id: 25, name: "Hematologia e hemoterapia" },
    { id: 26, name: "Homeopatia" },
    { id: 27, name: "Infectologia" },
    { id: 28, name: "Mastologia" },
    { id: 29, name: "Medicina de emergência" },
    { id: 30, name: "Medicina de família e comunidade" },
    { id: 31, name: "Medicina do trabalho" },
    { id: 32, name: "Medicina do tráfego" },
    { id: 33, name: "Medicina esportiva" },
    { id: 34, name: "Medicina física e reabilitação" },
    { id: 35, name: "Medicina intensiva" },
    { id: 36, name: "Medicina legal e perícia médica" },
    { id: 37, name: "Medicina nuclear" },
    { id: 38, name: "Medicina preventiva e social" },
    { id: 39, name: "Nefrologia" },
    { id: 40, name: "Neurocirurgia" },
    { id: 41, name: "Neurologia" },
    { id: 42, name: "Nutrologia" },
    { id: 43, name: "Oftalmologia" },
    { id: 44, name: "Oncologia clínica" },
    { id: 45, name: "Ortopedia e traumatologia" },
    { id: 46, name: "Otorrinolaringologia" },
    { id: 47, name: "Patologia" },
    { id: 48, name: "Patologia clínica/medicina laboratorial" },
    { id: 49, name: "Pediatria" },
    { id: 50, name: "Pneumologia" },
    { id: 51, name: "Psiquiatria" },
    { id: 52, name: "Radiologia e diagnóstico por imagem" },
    { id: 53, name: "Radioterapia" },
    { id: 54, name: "Reumatologia" },
    { id: 55, name: "Urologia" },
  ];
  listMedicoAcademico: any[] = [
    { name: "MBA de Gestão e Inovação em Saúde" },
    { name: "Cuidados Paliativos" },
    { name: "Emergências Pediátricas" },
    { name: "Geriatria" },
    { name: "Medicina Intensiva de Adulto" },
    { name: "Medicina de Emergência" },
    { name: "Medicina Intensiva de Adulto" },
    { name: "Cardiologia e Medicina do Estilo de Vida" },
    { name: "Direito Médico" },
    { name: "Nutrologia" },
    { name: "Endocrinologia" },
    { name: "Neurologia" },
    { name: "Psiquiatria" },
    { name: "Transporte Aeromédico e Medicina Aeroespacial" },
  ];
  listEnfermeiroOutros: any[] = [
    { name: "MBA de Gestão e Inovação em Saúde" },
    { name: "Cuidados Paliativos" },
    { name: "Transporte Aeromédico e Medicina Aeroespacial" },
  ];
  listOthersAreaActing: any[] = [
    { name: "Fisioterapeuta" },
    { name: "Fonoaudiólogo" },
    { name: "Nutricionista" },
    { name: "Odontólogo" },
    { name: "Psicólogo" },
    { name: "Socorrista" },
    { name: "Técnico em Enfermagem" },
    { name: "Outro" },
  ];
  payForm: FormGroup;
  discountForm: FormGroup;
  formCompany: FormGroup;
  complementaryCourses: any = [];
  complementaryCoursesSelected = [];
  alreadySubscripted: boolean = false;
  termsAccepted: boolean = false;
  communication: boolean = true;
  dataSharing: boolean = false;
  courseUnavailable: boolean = false;
  courseFinanciable: boolean = false;
  redirectAccepted: boolean = false;
  step: number = 1;
  isTerzius: boolean = false;
  loadingFinance: boolean = false;
  isCoparticipacaoPI: boolean = false;
  displayTerms: boolean = false;
  currentTermsContent: any;
  isCompany: boolean = false;
  isCoparticipation: boolean = false;
  isClass: boolean = false;
  isType: string;
  isCategory: string;
  isCategoryType: string;
  isCategoryValue: number;
  isCourseID: string;
  isPayMethodb2b: string = "";
  isPaymentValues: boolean = false;
  isPaymentValuesTwo: boolean = false;

  isGeneralist: boolean = false;
  isAcademic: boolean = false;

  // deliverOptions: any;
  showDeliverAddress: boolean = false;

  show_dados_pessoais: boolean = true;
  show_pagamento: boolean = false;
  show_confirmacao: boolean = false;
  checkout_finished: boolean = false;
  showRegistry: boolean = false;
  showYearGraduation: boolean = false;
  showTipo: boolean = false;
  showAreaActing: boolean = false;
  showNewEspecialization: boolean = false;
  showMoreAreas: boolean = false;
  showOthersAreaActing: boolean = false;

  paymentMethod: string = "";
  paymentMethodB2B: string = "";
  showShipping: boolean = false;
  showShippingEbook: boolean = false;
  showShippingOrganizer: boolean = false;
  isCalculatingShipping: boolean = false;
  isLookingAddress: boolean = false;
  isLookingEmail: boolean = false;
  isLookingCpf: boolean = false;
  isApplyingCoupom: boolean = false;
  isConcluding: boolean = false;

  copyInfo = [{ name: "Dados pessoais", code: "1" }];
  installments: any = [];
  professions: any = [];
  specializations: any = [];
  types: any[] = [
    { name: "Residente" },
    { name: "Especialista" },
    { name: "Generalista" },
  ];
  areas_especialization: String[] = [];

  disallowCard: boolean = false;
  disallowBillet: boolean = false;

  disallowFinancing: boolean = false;
  disallowFinancingMessage: boolean = false;

  disallowCardComplementary: boolean = false;
  disallowBilletComplementary: boolean = false;

  disallowCardMessage = "";
  disallowBilletMessage = "";

  allowPac: boolean;
  allowSedex: boolean;
  allowEbook: boolean;
  allowOrganizer: boolean;
  allowRetrieve: boolean;

  organizator: any = [];

  courseId: any;
  courseValue = 0;
  courseFee = 0;
  courseDiscount = 0;
  coupomId = null;
  private _courseValueBackup: any = 0;
  checkAddressShipping: Subject<string> = new Subject<string>();
  checkPaymentAddressShipping: Subject<string> = new Subject<string>();
  captchaResponse: string = "";
  shippingValue: number = 0;
  shipping_time: number = 0;
  utm: any = {};
  is_pos: boolean = false;
  isAtls: boolean = false;

  private _courses = [];

  finishResponse = {
    status: "ok",
    message: "",
    messageInvoice: "",
    processorCode: "",
  };

  _categoria = [];

  _clientes = [];

  _payment_finc = [];

  _payment_finc_two = [];

  _payment_finc_tree = [];

  isITerzi: boolean;

  communicationPhrase: any;

  i_courses = [
    { name: "CLINICS" },
    { name: "CLINICS PED" },
    { name: "CONDUCT" },
    { name: "CRITIC" },
    { name: "INFECTUS" },
    { name: "PROCEM" },
    { name: "PROCEM PED" },
    { name: "SMART-ATB" },
    { name: "USEM" },
    { name: "VENTILEM" },
  ];

  constructor(
    @Inject(WINDOW) private window: Window,
    private fb: FormBuilder,
    private _coreService: CoreService,
    private _currencyPipe: CurrencyPipe,
    private _snotifyService: SnotifyService,
    private _seoService: SeoService,
    private _activatedRoute: ActivatedRoute
  ) {
    // this.deliverOptions = Constants.deliverOptions;
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse["response"];
  }

  ngOnInit() {
    this.communicationPhrase =
      Constants.getCommunicationPhrases[
        Math.floor(Math.random() * Constants.getCommunicationPhrases.length)
      ];
    this.courseData = this._activatedRoute.snapshot.data.courseData;
    this.courseData.is_package
      ? (this.courseId = this.courseData.id)
      : (this.courseId = this.courseData.course[0].id);
    this.courseValue = this.courseData.value;
    this._courseValueBackup = this.courseData.value;
    if (this.courseData.is_package) {
      this.package_payments = this.courseData.package_payments;
    }
    this._activatedRoute.queryParams.subscribe((routeParams) => {
      if (routeParams.utm_source) {
        this.utm.utm_source = routeParams.utm_source;
      }
      if (routeParams.utm_medium) {
        this.utm.utm_medium = routeParams.utm_medium;
      }
      if (routeParams.utm_campaign) {
        this.utm.utm_campaign = routeParams.utm_campaign;
      }
      if (routeParams.utm_value) {
        this.utm.utm_campaign = routeParams.utm_value;
      }
    });

    if (!this.courseData.allow_card) {
      this.disallowCard = true;
      this.disallowCardMessage = this.courseData.disallow_card_reason;
    }

    if (!this.courseData.allow_billet) {
      this.disallowBillet = true;
      this.disallowBilletMessage = this.courseData.disallow_billet_reason;
    }

    if (!this.courseData.allow_financing) {
      this.disallowFinancing = true;
      this.disallowFinancingMessage = this.courseData.disallow_financing_reason;
    }

    if (!this.courseData.allow_subscription) {
      this.courseUnavailable = true;
    }

    if (this.courseData.organizator != undefined) {
      this.organizator = this.courseData.organizator;
    }

    this.allowPac = this._checkAllowPac();
    this.allowSedex = this._checkAllowSedex();
    this.allowEbook = this._checkAllowEbook();
    this.allowOrganizer = this._checkAllowOrganizer();
    this.allowRetrieve = this._checkAllowRetrieve();

    this._activatedRoute.url.subscribe((url: any) => {
      let path = "";
      url.map((item) => (path += "/" + item.path));
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    //this._getComplementaryCourses();

    this.form = this.fb.group({
      name: [null, [Validators.required]],
      phone: [null, [Validators.required, Validators.minLength(10)]],
      email: [null, [Validators.required, Validators.email]],
      cpf: [null, [Validators.required, GenericValidator.isValidCpf()]],
      specialization: [null],

      shipping_type: [null, [Validators.required]],

      cep: ["", [Validators.required, Validators.minLength(8)]],
      address: [{ value: null, disabled: false }, [Validators.required]],
      number: [null, [Validators.required, Validators.minLength(1)]],
      complement: [null],
      neighborhood: [{ value: null, disabled: false }, [Validators.required]],
      city: [{ value: null, disabled: false }, [Validators.required]],
      uf: [{ value: null, disabled: false }, [Validators.required]],

      profession: [null, [Validators.required]],
      professional_registry: [{ value: null, disabled: true }, []],
      other_area_acting: [{ value: null, disabled: true }, []],
      professional_moment: [{ value: null, disabled: true }, []],
      area_acting: [{ value: null, disabled: true }, []],
      area_especialization: [null],
      year_graduation: [{ value: null, disabled: true }, []],
      interest_another_area: null,
      interested_another_specialization: null,
      interested_specialization: null
    });

    this._checkAllowShippingType();

    this.discountForm = this.fb.group({
      coupom: [null],
    });

    this.payForm = this.fb.group({
      payment_method: [null, [Validators.required]],
      installments: [1, [Validators.required]],

      set_number: [null],
      set_exp_month: [null],
      set_exp_year: [null],
      set_cvv_number: [null],

      copy_info: [null],

      name_in_card: [null],
      email: [null],
      birth_date: [null],
      cpf: [null],
      phone: [null],
      cep: [null],
      address: [{ value: null, disabled: true }],
      number: [null],
      complement: [null],
      neighborhood: [{ value: null, disabled: true }],
      city: [{ value: null, disabled: true }],
      uf: [{ value: null, disabled: true }],
    });

    this.formCompany = this.fb.group({
      company_id: [null],
      type: [null],
      coparticipation: [null],
      coparticipation_category: [null],
      payment_method: [null],
    });

    this.courseData.course.map((course) => {
      let data = {
        id: course.id,
        value: course.value ? course.value : 0,
        shipping_value: 0,
        categoria: course.b2b.coparticipation_category,
      };
      this._courses.push(data);
      this.isCourseID = data.id;
    });

    // Card de pagamentos
    this.courseData.course.forEach((pay) => {
      pay.payment.forEach((payType) => {
        if (payType.fee === true) {
          let data = {
            fee: payType.fee,
            fee_msg: payType.fee_msg,
            payment_method: payType.payment_method,
            payment_type: payType.payment_type,
            total_amount: payType.total_amount,
          };
          this._payment_finc.push(data);
          this.isPaymentValues = true;
        } else if (
          payType.fee === false &&
          payType.payment_method == "Cartão"
        ) {
          let data2 = {
            fee: payType.fee,
            fee_msg: payType.fee_msg,
            payment_method: payType.payment_method,
            payment_type: payType.payment_type,
            total_amount: payType.total_amount,
          };
          this._payment_finc_two.push(data2);
          this.isPaymentValuesTwo = true;
        } else if (payType.payment_method == "Boleto") {
          let data3 = {
            fee: payType.fee,
            fee_msg: payType.fee_msg,
            payment_method: payType.payment_method,
            payment_type: payType.payment_type,
            total_amount: payType.total_amount,
          };
          this._payment_finc_tree.push(data3);
        }
      });
    });

    //Bloco para verificação se o curso é b2b ou não (Bruno)
    this.courseData.course.forEach((courseB2B) => {
      if (courseB2B.b2b.length === 0) {
      } else {
        this.company();
      }
    });

    // Verificando se a turma é open_class ou closed_class
    this.courseData.course.forEach((courseB2B) => {
      if (courseB2B.b2b.type == "closed_class") {
        this.isType = courseB2B.b2b.type;

        this.isCourseID = courseB2B.b2b.company_id;
        if (courseB2B.b2b.coparticipation !== true) {
        } else {
          this.coparticipationPrincipal();
          // Preenchendo o array _categoria com as categorias do B2B
          this.courseData.course.forEach((course) => {
            course.b2b.coparticipation_category.forEach((category) => {
              let data = {
                id: category.id_category,
                categoria: category.category,
                type: category.type,
                value: category.value,
              };
              this._categoria.push(data);
            });
          });
        }
      } else {
        this.classOC();
        this.isType = "open_class";
      }
    });

    // Preenchendo o array _clientes com as categorias do B2B
    this.courseData.course.forEach((course) => {
      course.b2b.forEach((company) => {
        let data = {
          id: company.company_id,
          name: company.company_name,
          coparticipation: company.coparticipation,
          categories: company.coparticipation_category,
        };
        this._clientes.push(data);
      });
    });

    // Preenchendo o array _categoria com as categorias do B2B
    this._clientes.forEach((clientes) => {
      clientes.categories.forEach((category) => {
        let data = {
          id: category.id_category,
          categoria: category.category,
          type: category.type,
          value: category.value,
        };
        this._categoria.push(data);
      });
    });

    this.checkAddressShipping
      .pipe(debounceTime(500))
      .subscribe((text) => this._getAddressData(text));
    this.checkPaymentAddressShipping
      .pipe(debounceTime(500))
      .subscribe((text) => this._getPaymentAddressData(text));

    this._getProfessions();
    this._getSpecializations();
    if (this.courseData.pos == 1) {
      this.is_pos = true;
    }

    if (this.courseData.sigla == "ATLS") {
      this.isAtls = true;
    }

    this.__gtag_send_function("cart-checkout-ficha-inscricao");

    this.courseData.name.indexOf("I-Terzi") > -1
      ? (this.isITerzi = true)
      : false;
  }

  // Função para validar se o cliente (open_class) tem coparticipação ou não
  clientesValidation(e) {
    if (e.value.coparticipation === true) {
      this.coparticipationPrincipal();
    } else {
      this.coparticipationPrincipalTwo();
    }
    if (this.isType == "open_class") {
      this.isCourseID = e.value.id;
    }
  }

  categoryEvent(e) {
    this.isCategory = e.value.id;
  }

  // Função para validar closed ou opene class
  classOC() {
    !this.isClass ? (this.isClass = true) : false;
  }

  // Função para validar se tem a parceria e tem pagamento com empresa
  coparticipationPrincipal() {
    !this.isCoparticipation ? (this.isCoparticipation = true) : false;
  }
  // Função para validar se tem a parceria e não tem pagamento com empresa
  coparticipationPrincipalTwo() {
    this.isCoparticipation ? (this.isCoparticipation = false) : false;
  }

  // Função para validar se tem Parceria ou Não
  company() {
    !this.isCompany ? (this.isCompany = true) : false;
  }

  // Define se tem copartipação do valor (parcial)
  coparticipacao() {
    !this.isCoparticipacaoPI ? (this.isCoparticipacaoPI = true) : false;
  }

  // Define se o valor é total para empresa (integral)
  valorCheio() {
    this.isCoparticipacaoPI ? (this.isCoparticipacaoPI = false) : false;
  }

  definePaymentMethodB2B(method: string): void {
    if (method == "b2b_credit_card") {
      this.isPayMethodb2b = "credit_card";
    }
    if (method == "b2b_invoice") {
      this.isPayMethodb2b = "invoice";
    }
    if (method == "b2b_financing") {
      this.isPayMethodb2b = "financing";
    }
    this.paymentMethodB2B = method;
  }

  ngOnDestroy() {
    this.checkAddressShipping.unsubscribe();
  }

  // ----- PUBLIC METHODS
  searchClasses(): void {
    window.location.href =
      "cursos?search_text=" +
      encodeURIComponent(this.courseData.course[0].fullname) +
      "&city_id=" +
      this.courseData.city_code;
  }

  setSendType(value: string): void {
    this.showShippingEbook = false;
    this.showShippingOrganizer = false;
    if (value.toUpperCase() == "OTHER") {
      this.form.patchValue({ shipping_type: value });
      this.showShipping = false;
      this.shippingValue = 0;
      this.courseValue = parseFloat(this._courseValueBackup);
      // this._courses.map(course => course['shipping_value'] = 0)
      // this.complementaryCoursesSelected.map(course => course['shipping_value'] = 0)
      return;
    }

    if (value.toUpperCase() == "ORGANIZER") {
      this.showShippingOrganizer = true;
      this.form.patchValue({ shipping_type: value });
      this.showShipping = false;
      this.shippingValue = 0;
      this.courseValue = parseFloat(this._courseValueBackup);
      // this._courses.map(course => course['shipping_value'] = 0)
      // this.complementaryCoursesSelected.map(course => course['shipping_value'] = 0)
      return;
    }

    if (value.toUpperCase() == "EBOOK") {
      this.form.patchValue({ shipping_type: value });
      this.showShipping = false;
      this.showShippingEbook = true;
      this.shippingValue = 0;
      this.courseValue = parseFloat(this._courseValueBackup);
      // this._courses.map(course => course['shipping_value'] = 0)
      //  this.complementaryCoursesSelected.map(course => course['shipping_value'] = 0)
      return;
    }

    const zipcode = this._cleanNumbers(this.form.get("cep").value);

    if (
      (value.toUpperCase() == "SEDEX" || value.toUpperCase() == "PAC") &&
      zipcode.length != 8
    ) {
      this._snotifyService.error(
        "Preencha o campo de CEP primeiro para calcular o valor do frete.",
        "",
        {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: "centerTop",
        }
      );
    } else {
      this.form.patchValue({ shipping_type: value });
      this._getShippingValue(this._cleanNumbers(this.form.get("cep").value));
    }
  }

  checkCoupom(show = true): void {
    if (!this.discountForm.get("coupom").value) return;

    this.isApplyingCoupom = true;

    let ids = [];
    if (this.complementaryCoursesSelected.length > 0) {
      this.complementaryCoursesSelected.map((course) => {
        ids.push(course.id);
      });
    }

    this._courses.map((course) => {
      ids.push(course.id);
    });

    const params = {
      coupom: this.discountForm.get("coupom").value,
      "courses_ids[]": ids,
      cpf: this.form.get("cpf").value,
      payment_method: this.paymentMethod,
      installment: this.payForm.controls.installments.value,
    };

    this._coreService
      .checkCoupom(params)
      .pipe(finalize(() => (this.isApplyingCoupom = false)))
      .subscribe((response: any) => {
        if (response.status == "error") {
          this._snotifyService.error(response.message, "", {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });

          return;
        }

        this.coupomId = response[0].id;

        response.map((coupom) => {
          this.complementaryCoursesSelected.map((course) => {
            if (coupom.course_id == course.id) {
              course["discount"] = coupom.value;
            }
          });

          if (coupom.course_id == this.courseId) {
            this.courseDiscount = coupom.value;
          }
        });

        if (show) {
          this._snotifyService.success("Cupom aplicado com sucesso.", "", {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });
        }

        if (this.paymentMethod == "invoice" || this.paymentMethod == "") {
          let frete =
            this.getMainCourseShipping() /
            (this.complementaryCoursesSelected.length + 1);

          /** Valor A VISTA */
          this.courseValue =
            this._courseValueBackup + frete - this.courseDiscount;
        }

        this._getInstallmentInfo();
      });
  }

  definePaymentMethod(method: string): void {
    if (
      method == "credit_card" &&
      (this.disallowCard || this.disallowCardComplementary)
    ) {
      this._snotifyService.error(this.disallowCardMessage, "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });
      return;
    }

    if (
      method == "invoice" &&
      (this.disallowBillet || this.disallowBilletComplementary)
    ) {
      this._snotifyService.error(this.disallowBilletMessage, "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });
      return;
    }
    if (
      method == "company_partnership" &&
      (this.disallowBillet || this.disallowBilletComplementary)
    ) {
      this._snotifyService.error(this.disallowBilletMessage, "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });
      return;
    }

    if (
      method == "financing" &&
      (this.disallowBillet || this.disallowBilletComplementary)
    ) {
      this._snotifyService.error(this.disallowBilletMessage, "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });
      return;
    }

    this.paymentMethod = method;
    this.payForm.patchValue({ payment_method: method });

    if (method == "credit_card") {
      this._getInstallmentInfo();

      this.payForm.get("set_number").setValidators([Validators.required]);
      this.payForm.get("set_exp_month").setValidators([Validators.required]);
      this.payForm.get("set_exp_year").setValidators([Validators.required]);
      this.payForm.get("set_cvv_number").setValidators([Validators.required]);

      this.payForm.get("name_in_card").setValidators([Validators.required]);
      this.payForm
        .get("email")
        .setValidators([Validators.required, Validators.email]);
      this.payForm.get("birth_date").setValidators([Validators.required]);
      this.payForm
        .get("cpf")
        .setValidators([Validators.required, GenericValidator.isValidCpf()]);
      this.payForm.get("phone").setValidators([Validators.required]);

      this.payForm
        .get("cep")
        .setValidators([Validators.required, Validators.minLength(8)]);
      this.payForm.get("address").setValidators([Validators.required]);
      this.payForm
        .get("number")
        .setValidators([Validators.required, Validators.minLength(1)]);
      this.payForm.get("neighborhood").setValidators([Validators.required]);
      this.payForm.get("city").setValidators([Validators.required]);
      this.payForm.get("uf").setValidators([Validators.required]);

      this.payForm.patchValue({ installments: null });
    } else if (method == "financing") {
      this._getInstallmentFinanceInfo();

      this.payForm.get("set_number").clearValidators();
      this.payForm.get("set_exp_month").clearValidators();
      this.payForm.get("set_exp_year").clearValidators();
      this.payForm.get("set_cvv_number").clearValidators();

      this.payForm.get("name_in_card").clearValidators();
      this.payForm.get("email").clearValidators();
      this.payForm.get("birth_date").clearValidators();
      this.payForm.get("cpf").clearValidators();
      this.payForm.get("phone").clearValidators();

      this.payForm.get("cep").clearValidators();
      this.payForm.get("address").clearValidators();
      this.payForm.get("number").clearValidators();
      this.payForm.get("neighborhood").clearValidators();
      this.payForm.get("city").clearValidators();
      this.payForm.get("uf").clearValidators();

      this.payForm.patchValue({ installments: null });
      //this.calculeFee(this.payForm.get('installments').value, true);
    } else if (method == "company_partnership") {
      this._getInstallmentInfo();

      this.formCompany.get("company_id").value;
      this.formCompany.get("type").value;
      this.formCompany.get("coparticipation").value;
      this.formCompany.get("coparticipation_category").value;
      this.formCompany.get("payment_method").value;
    } else {
      this.payForm.get("set_number").clearValidators();
      this.payForm.get("set_exp_month").clearValidators();
      this.payForm.get("set_exp_year").clearValidators();
      this.payForm.get("set_cvv_number").clearValidators();

      this.payForm.get("name_in_card").clearValidators();
      this.payForm.get("email").clearValidators();
      this.payForm.get("birth_date").clearValidators();
      this.payForm.get("cpf").clearValidators();
      this.payForm.get("phone").clearValidators();

      this.payForm.get("cep").clearValidators();
      this.payForm.get("address").clearValidators();
      this.payForm.get("number").clearValidators();
      this.payForm.get("neighborhood").clearValidators();
      this.payForm.get("city").clearValidators();
      this.payForm.get("uf").clearValidators();

      this.payForm.patchValue({ installments: 1 });
      this.calculeFee(this.payForm.get("installments").value);
    }

    this.payForm.get("set_number").updateValueAndValidity();
    this.payForm.get("set_exp_month").updateValueAndValidity();
    this.payForm.get("set_exp_year").updateValueAndValidity();
    this.payForm.get("set_cvv_number").updateValueAndValidity();

    this.payForm.get("name_in_card").updateValueAndValidity();
    this.payForm.get("email").updateValueAndValidity();
    this.payForm.get("birth_date").updateValueAndValidity();
    this.payForm.get("cpf").updateValueAndValidity();
    this.payForm.get("phone").updateValueAndValidity();

    this.payForm.get("cep").updateValueAndValidity();
    this.payForm.get("address").updateValueAndValidity();
    this.payForm.get("number").updateValueAndValidity();
    this.payForm.get("neighborhood").updateValueAndValidity();
    this.payForm.get("city").updateValueAndValidity();
    this.payForm.get("uf").updateValueAndValidity();

    this.payForm.get("installments").markAsPristine();
    this.payForm.get("installments").markAsUntouched();
  }

  /** Calcula o valor do curso individualmente */
  calculeFee(installment, finance = false, invoice = false): void {
    for (let i in this.installments) {
      if (this.installments[i].value == installment) {
        this.labelOptionsPayment = this.installments[i].label;
      }
    }

    const feeRate = Constants.installmentFee[installment];

    /** Get IDs Courses */
    const course = this._courses.map((course) => course.id);
    const complementary = this.complementaryCoursesSelected.map(
      (course) => course.id
    );
    const courseIds = course;

    let frete =
      this.getMainCourseShipping() /
      (this.complementaryCoursesSelected.length + 1);
    let valor = this.courseValue + frete - this.courseDiscount;

    let package_id = 0;
    if (this.courseData.is_package) {
      package_id = this.courseData.id;
    } else {
      package_id = undefined;
    }

    let params = {
      is_package: this.courseData.is_package,
      package_id: package_id,
      value: valor,
      blackfriday: this.courseData.black_friday,
      courseIds: courseIds,
      coupomId: this.coupomId,
      shippingValue: frete,
    };

    if (finance == false && invoice == false) {
      this._coreService.getInterestCard(params).subscribe((response: any) => {
        this.courseFee = response.find(
          (x) => x.installment == installment
        ).installment_fee;

        this.courseValue = response.find(
          (x) => x.installment == installment
        ).full_price;
      });

      this.complementaryCoursesSelected.map((course) => {
        let valorComplementar = 0;
        let frete =
          this.getMainCourseShipping() /
          (this.complementaryCoursesSelected.length + 1);
        valorComplementar = this.toNumber(course.value) + frete;
        valorComplementar -= this.courseDiscount;
        let id_course = [course.id];
        //courseIds.push(course.id)
        //course['fee'] = (parseFloat(course['value']) / 100) * feeRate

        let package_id = 0;
        if (this.courseData.is_package) {
          package_id = this.courseData.id;
        } else {
          package_id = undefined;
        }

        let params = {
          is_package: this.courseData.is_package,
          package_id: package_id,
          value: valorComplementar,
          blackfriday: false,
          courseIds: id_course,
          coupomId: this.coupomId,
          shippingValue: frete,
        };

        this._coreService.getInterestCard(params).subscribe((response: any) => {
          course["fee"] = response.find(
            (x) => x.installment == installment
          ).installment_fee;

          course["value"] = response.find(
            (x) => x.installment == installment
          ).full_price;
        });
      });
    } else if (invoice) {
      /** VALOR A VISTA SEM CUPOM */
      let frete =
        this.getMainCourseShipping() /
        (this.complementaryCoursesSelected.length + 1);
      this.courseValue = this._courseValueBackup + frete;

      this.complementaryCoursesSelected.map((course) => {
        course["value"] = course["valueBackup"] + frete;
        course["fee"] = 0;
      });
    } else {
      if (this.complementaryCoursesSelected.length > 0) {
        let priceInst = this.installments.find(
          (x) => x.value == installment
        ).price;

        let classFin = this.financeComplementaryInfo.info;
        this.complementaryCoursesSelected.map((course) => {
          let c = classFin.find((a) => a.id == course.id);
          course["fee"] = c.porc * priceInst - course["value"];
        });

        this.courseData.course.map((course) => {
          let c = classFin.find((a) => a.id == course.id);
          this.courseValue = c.porc * priceInst;
        });
      } else {
        // this.courseFee = this.installments.find(x => x.value == installment).fee;
        this.courseValue = this.installments.find(
          (x) => x.value == installment
        ).price;
      }
    }
  }

  gotoStep(step): void {
    this.step = step;
  }

  __gtag_send_function(event) {
    this._gtag_report_conversion(0, event);
  }

  gotoPaymentForm(): void {
    this.show_dados_pessoais = false;
    this.show_pagamento = true;
    this.window.scrollTo(0, 0);
  }

  gotoPersonalDataForm(): void {
    this.show_dados_pessoais = true;
    this.show_pagamento = false;

    this.paymentMethod = null;
    this.payForm.patchValue({ payment_method: null });

    this.window.scrollTo(0, 0);
  }

  // checkout(): void {
  //   const data = {};

  //   data["profession"]= this.form.get("profession").value.name;
  //   data["professional_registry"] = this.form.get("professional_registry").value;
  //   data["year_graduation"] = this.form.get("year_graduation").value;
  //   data["interest_another_area"] = this.form.get("interest_another_area").value;
  //   data["interest_another_area"] = this.form.get("interested_another_specialization").value;
  //   data["interested_specialization"] = this.form.get("interested_specialization").value;


  //   if (this.form.get("area_acting").value) {
  //     data["area_acting"] = this.form.get("area_acting").value.name;
  //   }

  //   if (this.form.get("area_especialization").value) {
  //     data["area_especialization"] = this.form.get("area_especialization").value.name;
  //   }

  //   if (this.form.get("professional_moment").value) {
  //     data["type"]= this.form.get("professional_moment").value.name;
  //   }



  //   console.log('data: ', data);
  //   console.log(this.form.value);
  // }

  checkout(): void {
    this.isConcluding = true;
    let data = {};

    data["personal_data"] = this.form.getRawValue();
    data["personal_data"]["phone"] = this._cleanNumbers(
      this.form.get("phone").value
    );
    data["personal_data"]["communication"] = this.communication;
    data["personal_data"]["profession"] = this.form.get("profession").value.name;


    if (this.form.get("professional_moment").value && this.form.get("profession").value.name.includes("MÉDICO")) {
      data["personal_data"]["type"] =
      this.form.get("professional_moment").value.name;
    }
    else {
      data["personal_data"]["type"] = null;
    }

    if (this.form.get("area_especialization").value) {
      data["personal_data"]["area_especialization"] =
      this.form.get("area_especialization").value.name;
    }

    if (this.form.get("area_acting").value) {
      data["personal_data"]["area_acting"] =
      this.form.get("area_acting").value.name;
    }

    if (this.form.get("other_area_acting").value && this.form.get("profession").value.name.includes("OUTROS")) {
      data["personal_data"]["other_area_acting"] = this.form.get("other_area_acting").value.name;
    }
    else {
      data["personal_data"]["other_area_acting"] = null;
    }

    delete data["personal_data"]["professional_moment"];

    data["payment_method"] = this.payForm.get("payment_method").value;
    data["installments"] = this.payForm.get("installments").value;
    data["shippingTime"] = this.shipping_time;
    data["shippingValue"] = this.shippingValue;

    data["captchaResponse"] = this.captchaResponse;

    data["origem"] = "helpsmed";

    data["traffic_source"] = this.getCookie("__trf.src");
    data["_ga"] = this.getCookie("_ga");
    data["utm_source"] = this.utm.utm_source;
    data["utm_medium"] = this.utm.utm_medium;
    data["utm_campaign"] = this.utm.utm_campaign;
    data["utm_value"] = this.utm.utm_value;

    if (this.complementaryCoursesSelected.length > 0) {
      this.complementaryCoursesSelected.map((course) => {
        let data = {
          id: course.id,
          value: course.value ? course.value : 0,
          shipping_value: course.shipping_value,
        };
        this._courses.push(data);
      });
    }

    if (this.coupomId) {
      this._courses = this._courses.map((item) => {
        let course = item;
        course["coupom_id"] = this.coupomId;
        return course;
      });
    }

    data["courses"] = this._courses;

    if (this.courseData.is_package) {
      data["packet_id"] = this.courseData.id;
    }

    data["data_sharing"] = this.dataSharing;

    if (this.payForm.get("payment_method").value == "company_partnership") {
      data["company_partnership"] = this.formCompany.getRawValue();
      data["company_partnership"]["company_id"] = this.isCourseID;
      data["company_partnership"]["type"] = this.isType;
      data["company_partnership"]["coparticipation"] = this.isCoparticipation;
      data["company_partnership"]["coparticipation_category"] = this.isCategory;
      data["company_partnership"]["payment_method"] = this.isPayMethodb2b;
    }

    if (this.payForm.get("payment_method").value == "credit_card") {
      data["credit_card"] = this.payForm.getRawValue();
      data["credit_card"]["phone"] = this._cleanNumbers(
        this.payForm.get("phone").value
      );
      data["credit_card"]["cep"] = this._cleanNumbers(
        this.payForm.get("cep").value
      );
      data["credit_card"]["birth_date"] = DateFormat.format(
        new Date(this.payForm.get("birth_date").value)
      );

      delete data["credit_card"]["payment_method"];
      delete data["credit_card"]["installments"];
    }
    data["communication"] = this.communicationPhrase;

    console.log('heiinn: ', data);

    this._coreService
      .courseSubscription(data)
      .pipe(finalize(() => (this.isConcluding = false)))
      .subscribe(
        (response: any) => {
          response.message = response.message.replace(
            "{base_url}",
            "https://" + this.window.location.hostname
          );
          response.processorCode =
            response.processorCode != "" && response.processorCode != undefined
              ? response.processorCode
              : this.finishResponse.processorCode;
          response.processorCode = this.formatProcessorCode(
            response.processorCode
          );
          this.finishResponse = response;
          this.checkout_finished = true;

          let value = 0;
          if (this.complementaryCoursesSelected.length > 0) {
            this.complementaryCoursesSelected.map((course) => {
              value += parseFloat(course.value) - parseFloat(course.discount);
            });
          }
          value +=
            this.courseData.value - this.courseDiscount + this.shippingValue;
          let gtagConversion =
            "conversion-" + this.payForm.get("payment_method").value;

            this._gtag_report_conversion(value, gtagConversion);
        },
        (response) => {
          if (response.error) {
            this._snotifyService.error(response.error, "", {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: "centerTop",
            });
          } else {
            this._snotifyService.error(
              "Algo deu errado na sua requisição, tente novamente em instantes.",
              "",
              {
                timeout: 3000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: "centerTop",
              }
            );
          }
        }
      );
  }

  formatProcessorCode(pc) {
    if (pc != undefined && pc.length > 0) {
      pc = pc.match(new RegExp(".{1,12}", "g"));
      return pc.join(" ");
    }
  }

  copyAddress(e: any): void {
    if (e.value.code == 1) {
      this.payForm.patchValue({
        email: this.form.get("email").value,
        cpf: this.form.get("cpf").value,
        phone: this.form.get("phone").value,
        cep: this.form.get("cep").value,
        address: this.form.get("address").value,
        number: this.form.get("number").value,
        complement: this.form.get("complement").value,
        neighborhood: this.form.get("neighborhood").value,
        city: this.form.get("city").value,
        uf: this.form.get("uf").value,
      });
    }
  }

  addComplementaryCourse(event: any): void {
    let alreadyAdded = false;

    this.complementaryCoursesSelected.map((course) => {
      if (course.id == event.id) {
        alreadyAdded = true;
      }
    });

    if (!alreadyAdded) {
      this.complementaryCoursesSelected.push(event);
      if (
        this.form.get("shipping_type").value != null &&
        (this.form.get("shipping_type").value.toUpperCase() == "SEDEX" ||
          this.form.get("shipping_type").value.toUpperCase() == "PAC") &&
        this.form.get("cep").value
      ) {
        this._getShippingValue(this.form.get("cep").value);
      }

      this.calculeFee(this.payForm.get("installments").value);
    }
    this._getInstallmentInfo();
    this._checkPaymentsAvailable();
    this.allowPac = this._checkAllowPac();
    this.allowSedex = this._checkAllowSedex();
    this.allowEbook = this._checkAllowEbook();
    this.allowOrganizer = this._checkAllowOrganizer();
    this.allowRetrieve = this._checkAllowRetrieve();
    this._checkAllowShippingType();
  }

  removeComplementaryCourse(id): void {
    this.complementaryCoursesSelected =
      this.complementaryCoursesSelected.filter((course) => {
        if (course.id == id) {
          return false;
        }
        return true;
      });

    if (
      this.form.get("shipping_type").value != null &&
      (this.form.get("shipping_type").value.toUpperCase() == "SEDEX" ||
        this.form.get("shipping_type").value.toUpperCase() == "PAC") &&
      this.form.get("cep").value
    ) {
      this._getShippingValue(this.form.get("cep").value);
    }

    let idx;
    this.complementaryCourses.map((course, index) => {
      if (course.id == id) {
        idx = index;
        this.complementaryCourses[index].isRemoved = true;
      }
    });

    this.paymentMethod = "credit_card";
    let clean = function () {
      this.complementaryCourses[idx].isRemoved = false;
    };

    this.payForm.get("installments").setValue(1);
    setTimeout(clean.bind(this), 300);
    this._getInstallmentInfo();
    this._checkPaymentsAvailable();
    this.allowPac = this._checkAllowPac();
    this.allowSedex = this._checkAllowSedex();
    this.allowEbook = this._checkAllowEbook();
    this.allowOrganizer = this._checkAllowOrganizer();
    this.allowRetrieve = this._checkAllowRetrieve();
    this._checkAllowShippingType();
  }

  toNumber(value: any): number {
    return parseFloat(value);
  }

  checkTermsAvailable(): boolean {
    if (
      this.show_pagamento &&
      this.payForm.get("installments").value &&
      this.payForm.get("payment_method").value
    ) {
      return true;
    }

    return false;
  }

  checkDataSharingAvailable(): boolean {
    if (this.courseData.data_sharing) {
      return true;
    } else {
      return false;
    }
  }

  showTerms(courseId, courseName): void {
    let selectedcourses = [];

    this._courses.map((course) => {
      let data = {};
      data["id"] = course.id;
      course.shipping_value > 0
        ? (data["shipping_value"] = course.shipping_value)
        : "";
      this.coupomId ? (data["coupom_id"] = this.coupomId) : "";
      selectedcourses.push(data);
    });

    if (this.complementaryCoursesSelected.length > 0) {
      this.complementaryCoursesSelected.map((course) => {
        let data = {};
        data["id"] = course.id;
        course.shipping_value > 0
          ? (data["shipping_value"] = course.shipping_value)
          : "";
        this.coupomId ? (data["coupom_id"] = this.coupomId) : "";
        selectedcourses.push(data);
      });
    }

    const data = {
      name: this.form.get("name").value,
      cpf: this.form.get("cpf").value,
      email: this.form.get("email").value,
      profession: this.form.get("profession").value,
      professional_registry: this.form.get("professional_registry").value,
      cep: this.form.get("cep").value,
      address: this.form.get("address").value,
      number: this.form.get("number").value,
      complement: this.form.get("complement").value,
      neighborhood: this.form.get("neighborhood").value,
      city: this.form.get("city").value,
      uf: this.form.get("uf").value,
      installments: this.payForm.get("installments").value,
      payment_method: this.payForm.get("payment_method").value,
      courses: selectedcourses,
    };

    this._coreService.getTerms(data).subscribe((response: any) => {
      response.map((item) => {
        if (parseInt(item.course_id) == parseInt(courseId)) {
          this.currentTermsContent = item;
          this.currentTermsContent["name"] = courseName;
          this.displayTerms = true;
        }
      });
    });

    // const url = this._router.createUrlTree(['/termos-de-compromisso', courseId, courseName], { queryParams: [JSON.stringify(data)] } );
    // window.open(url.toString(), '_blank', 'width=1000, height=600')
  }

  closeTermsDialog(): void {
    this.displayTerms = false;
  }

  acceptTerms(value: boolean): void {
    this.termsAccepted = value;
  }

  acceptComunication(value: boolean): void {
    this.communication = value;
  }

  acceptDataSharing(value: boolean): void {
    this.dataSharing = value;
  }

  acceptRedirect(value: boolean): void {
    this.redirectAccepted = value;
  }

  getMainCourseShipping(): number {
    let complementaryShipping = 0;
    if (this.complementaryCoursesSelected.length > 0) {
      this.complementaryCoursesSelected.map((course) => {
        complementaryShipping += parseFloat(course.shipping_value);
      });
    }
    return this.shippingValue - complementaryShipping;
  }

  // ----- PRIVATE METHOD
  private _getAddressData(searchTerm: string): void {
    const zipcode = this._cleanNumbers(searchTerm);

    if (zipcode.length == 0) {
      this.form.patchValue({
        address: null,
        neighborhood: null,
        city: null,
        uf: null,
      });

      this.form.controls["address"].disable();
      this.form.controls["neighborhood"].disable();
      return;
    }

    if (zipcode.length < 8) return;
    let courses = [];

    if (this.courseData.is_package) {
      courses = this._courses.map((item) => {
        return item.id;
      });
    }

    this.isLookingAddress = true;
    let params = {
      cep: zipcode,
      type: this.form.get("shipping_type").value,
      "courses_ids[]": this.courseData.is_package
        ? courses
        : [parseInt(this.courseId)],
    };

    this._coreService
      .getAddress(params)
      .pipe(finalize(() => (this.isLookingAddress = false)))
      .subscribe((response: any) => {
        if (response.hasOwnProperty("erro")) {
          this._snotifyService.error(response.erro, "", {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });

          return;
        }

        const zipcode = this._cleanNumbers(this.form.get("cep").value);
        if (zipcode) {
          this.form.patchValue({
            address: response.address,
            neighborhood: response.neighborhood,
            city: response.city,
            uf: response.state,
          });

          if (!response.address) {
            this.form.controls["address"].enable();
          }

          if (!response.neighborhood) {
            this.form.controls["neighborhood"].enable();
          }
        }
      });
  }

  private _getPaymentAddressData(searchTerm: string): void {
    const zipcode = this._cleanNumbers(searchTerm);

    if (zipcode.length == 0) {
      this.form.patchValue({
        address: null,
        neighborhood: null,
        city: null,
        uf: null,
      });

      this.form.controls["address"].disable();
      this.form.controls["neighborhood"].disable();
      return;
    }

    if (zipcode.length < 8) return;

    this.isLookingAddress = true;
    let params = {
      cep: zipcode,
      type: this.form.get("shipping_type").value,
      "courses_ids[]": [parseInt(this.courseId)],
    };

    this._coreService
      .getAddress(params)
      .pipe(finalize(() => (this.isLookingAddress = false)))
      .subscribe((response: any) => {
        if (response.hasOwnProperty("erro")) {
          this._snotifyService.error(response.erro, "", {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });

          return;
        }

        const zipcode = this._cleanNumbers(this.payForm.get("cep").value);
        if (zipcode) {
          this.payForm.patchValue({
            address: response.address,
            neighborhood: response.neighborhood,
            city: response.city,
            uf: response.state,
          });

          if (!response.address) {
            this.payForm.controls["address"].enable();
          }

          if (!response.neighborhood) {
            this.payForm.controls["neighborhood"].enable();
          }
        }
      });
  }

  private _getShippingValue(searchTerm: string): void {
    this.showShipping = true;
    this.isCalculatingShipping = true;
    const zipcode = this._cleanNumbers(searchTerm);

    const course = this._courses.map((course) => course.id);
    const complementary = this.complementaryCoursesSelected.map(
      (course) => course.id
    );
    const courseIds = [...course, ...complementary];

    let params = {
      cep: zipcode,
      type: this.form.get("shipping_type").value,
      "courses_ids[]": courseIds,
    };

    this._coreService
      .getAddress(params)
      .pipe(finalize(() => (this.isCalculatingShipping = false)))
      .subscribe(
        (response: any) => {
          this.shipping_time = response.shipping_time;
          this.shippingValue = 0;
          this.shippingValue = response.shipping_value;
          // response.shipping_value.map(item => this.shippingValue += parseFloat(item.value));

          // response.shipping_value.map(item => {
          //   this._courses.map(course => {
          //     if(item.course_id == course.id){
          //       let shipping = parseFloat(item.value);
          //       course['shipping_value'] = shipping;
          //     }
          //   })
          // });

          // response.shipping_value.map(item => {
          //   this.complementaryCoursesSelected.map((course, index) => {
          //     if(item.course_id == course.id){
          //       let shipping = parseFloat(item.value);
          //       course['shipping_value'] = shipping;
          //     }
          //   })
          // });
        },
        () => {
          this.showShipping = false;
          this._snotifyService.error(
            "Não foi possível calcular o valor do frete.",
            "",
            {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: "centerTop",
            }
          );
        }
      );
  }

  private _getComplementaryCourses(): void {
    let params = { course_id: this.courseId };
    this._coreService
      .getComplementaryCourses(params)
      .subscribe((response: any) => {
        this.complementaryCourses = response.map((course) => {
          course["isRemoved"] = false;
          return course;
        });
      });
  }

  private _cleanNumbers(term: string): string {
    return term.replace(/\D/g, "");
  }

  private _getInstallmentInfo(): void {
    let value = 0;
    this.payForm.get("installments").disable({ onlySelf: true });
    /** Get IDs Courses */
    const course = this._courses.map((course) => course.id);
    const complementary = this.complementaryCoursesSelected.map(
      (course) => course.id
    );
    const courseIds = [...course, ...complementary];

    if (this.complementaryCoursesSelected.length > 0) {
      this.complementaryCoursesSelected.map((course) => {
        value += parseFloat(course.value) - parseFloat(course.discount);
      });
    }
    value += this.courseData.value - this.courseDiscount + this.shippingValue;
    let package_id = 0;
    if (this.courseData.is_package) {
      package_id = this.courseData.id;
    } else {
      package_id = undefined;
    }

    let params = {
      is_package: this.courseData.is_package,
      package_id: package_id,
      value: value,
      blackfriday: this.courseData.black_friday,
      courseIds: courseIds,
      coupomId: this.coupomId,
      shippingValue: this.shippingValue,
    };

    this._coreService.getInterestCard(params).subscribe((response: any) => {
      this.installments = response.map((item) => {
        let data = {
          label: `${item.installment}x de ${this._currencyPipe.transform(
            item.installment_price,
            "R$"
          )} (${
            item.installment_fee > 0 ? "com juros" : "sem juros"
          }) Total ${this._currencyPipe.transform(item.full_price, "R$ ")} `,
          price: item.full_price,
          fee: item.installment_fee,
          value: item.installment,
        };

        return data;
      });
      this.installments.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        // a must be equal to b

        return 0;
      });
      this.payForm.get("installments").enable();
      this.payForm.get("installments").setValue(1);
      if (this.paymentMethod == "credit_card") {
        this.calculeFee(this.installments[0].value);
      }
    });
  }

  private _getProfessions() {
    this.professions = [
      {
        name: "MÉDICO(A)",
      },
      {
        name: "ACADÊMICO DE MEDICINA",
      },
      {
        name: "ENFERMEIRO(A)",
      },
      {
        name: "OUTROS",
      },
    ];
    // this._coreService.getProfessions().subscribe((response: any) => {
    //   this.professions = response;
    // });
  }

  private _getSpecializations() {
    // this._coreService.getSpecializations().subscribe((response: any) => {
    // this.specializations = response;

    // });
    this.specializations = Constants.specializations;
  }

  private _checkPaymentsAvailable(): void {
    if (this.complementaryCoursesSelected.length == 0) {
      this.disallowBilletComplementary = false;
      this.disallowCardComplementary = false;
      this.disallowBilletMessage = this.courseData.disallow_billet_reason;
      this.disallowCardMessage = this.courseData.disallow_card_reason;
      return;
    }

    for (let i = 0; i < this.complementaryCoursesSelected.length; i++) {
      if (this.complementaryCoursesSelected[i].allow_billet == false) {
        this.disallowBilletComplementary = true;
        this.disallowBilletMessage =
          this.complementaryCoursesSelected[i].disallow_billet_reason;
        break;
      } else {
        this.disallowBilletComplementary = false;
        this.disallowBilletMessage = this.courseData.disallow_billet_reason;
      }
    }

    for (let i = 0; i < this.complementaryCoursesSelected.length; i++) {
      if (this.complementaryCoursesSelected[i].allow_card == false) {
        this.disallowCardComplementary = true;
        this.disallowCardMessage =
          this.complementaryCoursesSelected[i].disallow_card_reason;
        break;
      } else {
        this.disallowCardComplementary = false;
        this.disallowCardMessage = this.courseData.disallow_card_reason;
      }
    }
  }

  private _checkAllowPac(): boolean {
    if (this.courseData.allow_pac) {
      return true;
    }

    for (let i = 0; i < this.complementaryCoursesSelected.length; i++) {
      if (this.complementaryCoursesSelected[i].allow_pac) {
        return true;
      }
    }

    return false;
  }

  private _checkAllowSedex(): boolean {
    if (this.courseData.allow_sedex) {
      return true;
    }

    for (let i = 0; i < this.complementaryCoursesSelected.length; i++) {
      if (this.complementaryCoursesSelected[i].allow_sedex) {
        return true;
      }
    }

    return false;
  }

  private _checkAllowEbook(): boolean {
    if (this.courseData.allow_ebook) {
      return true;
    }

    for (let i = 0; i < this.complementaryCoursesSelected.length; i++) {
      if (this.complementaryCoursesSelected[i].allow_ebook) {
        return true;
      }
    }

    return false;
  }

  private _checkAllowOrganizer(): boolean {
    if (this.courseData.allow_organizer) {
      return true;
    }

    for (let i = 0; i < this.complementaryCoursesSelected.length; i++) {
      if (this.complementaryCoursesSelected[i].allow_organizer) {
        return true;
      }
    }

    return false;
  }

  private _checkAllowRetrieve(): boolean {
    if (this.courseData.allow_retrieve) {
      return true;
    }

    for (let i = 0; i < this.complementaryCoursesSelected.length; i++) {
      if (this.complementaryCoursesSelected[i].allow_retrieve) {
        return true;
      }
    }

    return false;
  }

  private _checkAllowShippingType(): boolean {
    if (
      this._checkAllowPac() ||
      this._checkAllowEbook() ||
      this._checkAllowSedex() ||
      this._checkAllowRetrieve()
    ) {
      this.form.get("shipping_type").setValidators([Validators.required]);
      this.form.get("shipping_type").updateValueAndValidity();
      return;
    }

    this.form.get("shipping_type").clearValidators();
    this.form.get("shipping_type").updateValueAndValidity();
    return;
  }

  private _gtag_report_conversion(value, event_name, url = undefined): void {
    let callback = function () {
      if (typeof url != "undefined") {
        this.window.location = url;
      }
    };

    if (event_name.includes("conversion")) {
      (<any>this.window).gtag("event", event_name, {
        send_to: "AW-762035336/c3taCK2g-5UBEIj5rusC",
        value: value,
        currency: "BRL",
        transaction_id: "",
        event_callback: callback,
      });

      (<any>this.window).ga("send", {
        hitType: "event",
        eventCategory: "Conversao-Curem",
        eventAction: "Sucesso",
        eventLabel: "",
      });

      // (<any>this.window).gtag('config', 'UA-1887643-18', {'page_path': '/inscricao-realizada-com-sucesso.html'});
    } else {
      (<any>this.window).gtag("event", event_name, {
        event_category: "Inscrição",
        event_label: this.courseData.name,
        event_callback: callback,
      });
    }
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }

  private _getInstallmentFinanceInfo() {
    this.loadingFinance = true;
    this.payForm.get("installments").disable({ onlySelf: true });
    let value = 0; // Valor total complementar
    let valueComplementary = ""; //Valores COmplementares
    let id_course = "";
    let combo = this.courseData.is_package;

    this.courseData.course.map((course) => {
      if (id_course != "") {
        id_course += "," + course.id;
      } else {
        id_course = course.id;
      }
    });
    value += this.courseData.value - this.courseDiscount + this.shippingValue;
    valueComplementary += value;

    if (this.complementaryCoursesSelected.length > 0) {
      this.complementaryCoursesSelected.map((course) => {
        value += parseFloat(course.value) - parseFloat(course.discount);

        id_course += "," + course.id;

        valueComplementary += "," + course.value;
      });
    }

    let params = {
      value: value,
      blackfriday: this.courseData.black_friday,
      id_course: id_course,
      combo: combo,
      id_combo: this.courseData.id,
      complementaryValue: valueComplementary,
      coupomId: this.coupomId,
      shippingValue: this.shippingValue,
    };

    this._coreService.getInterestFinance(params).subscribe((response: any) => {
      this.installments = response.options.map((item) => {
        let data = {
          label: `${
            item.installmentsToApply
          }x de ${this._currencyPipe.transform(
            item.price,
            "R$"
          )} ( Valor Total: ${this._currencyPipe.transform(
            item.priceTotal,
            "R$"
          )})`,
          price: item.priceTotal,
          fee: item.monthlyInterest,
          value: item.installmentsToApply,
        };

        return data;
      });

      if (this.complementaryCoursesSelected.length > 0) {
        this.financeComplementaryInfo = response;
      } else {
        this.financeComplementaryInfo = [];
      }

      this.payForm.get("installments").enable();
      this.payForm.get("installments").setValue(9);
      this.calculeFee(9, true);
      this.loadingFinance = false;
    });
  }

  addToPossibleCartAbandonment(): void {
    const { cpf, email, name, phone } = this.form.value;
    const { id } = this.courseData.course[0];

    const cart: ClientCart = {
      id_curso: id,
      cpf,
      nome: name,
      telefone: phone,
      email,
      origem: "helpsmed"
    };

    this._coreService.addToCartAbandonment(cart).subscribe({
      error: (error) => console.error(error),
    });
  }

  filterActingArea(event: any): void {
    let params = event.query;

    this.areaActingMirror = [];

    this.listAreaActing.forEach((course) => {
      if (course.name.toLowerCase().includes(params.toLowerCase())) {
        this.areaActingMirror.push({
          alias: course.name,
          name: course.name,
          id: course.id,
        });
      }
    });
  }

  exibeCampos(event: any): void {
    const { name } = event.value;

    this.showYearGraduation = false;
    this.showRegistry = false;
    this.showTipo = false;
    this.isGeneralist = false;
    this.showAreaActing = false;
    this.isAcademic = false;
    this.showNewEspecialization = false;
    this.showMoreAreas = false;
    this.showOthersAreaActing = false;

    this.disableField("year_graduation");
    this.disableField("professional_moment");
    this.disableField("professional_registry");
    this.disableField('other_area_acting');
    this.disableField("area_acting");


    if (!name.includes("ACADÊMICO")) {
      this.showRegistry = true;

      this.enableField("professional_registry");

      if (name.includes("MÉDICO")) {
        this.form.get("professional_registry").setValue(null);
        this.form.get("professional_moment").setValue(null);
        this.form.get("area_acting").setValue(null);
        this.form.get("year_graduation").setValue(null);
        this.form.get("interested_specialization").setValue(null);

        this.showTipo = true;

        this.enableField("professional_moment");

        this.areas_especialization = [];
        this.areas_especialization = this.listMedicoAcademico;
      }

      if (name.includes("ENFERMEIRO(A)")) {
        this.form.get("professional_registry").setValue(null);
        this.form.get("area_acting").setValue(null);
        this.form.get("year_graduation").setValue(null);
        this.form.get("interested_specialization").setValue(null);

        this.isGeneralist = true;
        this.showMoreAreas = true;

        this.areas_especialization = [];
        this.areas_especialization = this.listEnfermeiroOutros;
      }

      if (name.includes("OUTROS")) {
        this.form.get("professional_registry").setValue(null);
        this.form.get("area_acting").setValue(null);
        this.form.get("year_graduation").setValue(null);
        this.form.get("interested_specialization").setValue(null);
        this.isAcademic = true;
        this.showOthersAreaActing = true;
        this.showMoreAreas = true;

        this.areas_especialization = [];

        this.enableField('other_area_acting');

      }
    } else {
      this.showYearGraduation = true;
      this.isAcademic = true;
      this.showMoreAreas = true;

      this.form.get("interested_specialization").setValue(null);
      this.form.get("professional_registry").setValue(null);
      this.form.get("area_acting").setValue(null);
      this.form.get("professional_moment").setValue(null);
      this.form.get("area_especialization").setValue(null);

      this.enableField("year_graduation");

      this.areas_especialization = [];
      this.areas_especialization = this.listMedicoAcademico;
    }
  }

  verificaTipo(event: any): void {
    const { name } = event.value;

    if (name != "Generalista") {
      this.showAreaActing = true;
      this.isGeneralist = false;
      this.showNewEspecialization = true;
      this.showMoreAreas = true;
      this.enableField('area_acting');
    } else {
      this.isGeneralist = true;
      this.showAreaActing = false;
      this.showNewEspecialization = false;
      this.showMoreAreas = true;
      this.disableField('area_acting');
    }
  }

  enableField(field: any): void {
    this.form.get(field).enable();
    this.form.get(field).setValidators(Validators.required);
  }

  disableField(field: any): void {
    this.form.get(field).disable();
    this.form.get(field).setValidators([]);
  }

  showNewAreas(event:any): void {
    const { name } = event.value;

    if (name.includes('Socorrista') || name.includes('Técnico')) {
        this.showMoreAreas = false;
        this.isAcademic = false;
    }else {
      this.showMoreAreas = true;
      this.isAcademic = true;
      this.areas_especialization = [];
      this.areas_especialization = this.listEnfermeiroOutros;
    }
  }
}
