import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { CoreService } from "src/app/core/core.service";
import { Seal } from "src/app/interfaces/seal";
import { SeoService } from "src/app/services/seo.service";
import { Constants } from "src/app/shared/constants.service";
import { WINDOW } from "@ng-toolkit/universal";

@Component({
  selector: "cm-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  teamList: any = [];
  sealList: Seal[];
  banner: string;
  texts: any = [];
  about: string = "HELPS";
  isTerzius: boolean;

  constructor(
    @Inject(WINDOW) private window: Window,
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService
  ) {}

  ngOnInit() {
    this._activatedRoute.url.subscribe((url: any) => {
      let path = "";
      url.map((item) => (path += "/" + item.path));
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    this._getTeam();
    this._getSeals();
    this._getBanner();
    this._getMessageProject();
  }

  // ----- PRIVATE METHODS
  private _getTeam(): void {
    this._coreService.getTeam().subscribe((response: any) => {
      this.teamList = response;
    });
  }

  private _getSeals(): void {
    this._coreService.getSeals().subscribe((response: any) => {
      this.sealList = response;
    });
  }

  private _getBanner(): void {
    const params = { page: "sobre-nos" };
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      // this.banner = response.image_url;
      this.banner = "../../assets/BG.jpg",
          
      console.log(response, 'aaa')
    });
  }

  private _getMessageProject(): void {
    const params = {
      "types[]": ["institutional", "mission", "vision", "values", "team"],
    };
    this._coreService.getTexts(params).subscribe((response: any) => {
      this.texts = response;
    });
  }
}
